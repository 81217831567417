<template>
  <component-frame title="Utilities">
    <div class="sg-page__heading">
      <div class="on-this-page">
        <div class="on-this-page-title">On This Page</div>
        <ul class="on-this-page-anchor-list">
          <li>
            <a href="#" v-scroll-to="'#loading-spinner'">Loading Spinners</a>
          </li>
          <li><a href="#" v-scroll-to="'#progress-bar'">Progress Bar</a></li>
        </ul>
      </div>
    </div>

    <h3 id="loading-spinner">Loading Spinners</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div
          class="sg-component__view"
          v-bind:class="{ 'is-loading': containerActive, '': !containerActive }"
        >
          <div class="close--loading" @click="containerLoad"></div>

          <h5>Buttons</h5>
          <button
            class="btn btn-primary"
            @click="clickLoad"
            v-bind:class="{ 'is-loading-inline': active, '': !active }"
          >
            Load on this element
          </button>

          <h5 class="mt-3">Inputs</h5>
          <div
            class="input-group"
            v-bind:class="{
              'is-loading-inline': fieldActive,
              '': !fieldActive
            }"
          >
            <input type="text" class="form-control" />
            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="inputLoad"
              >
                Load input
              </button>
            </div>
          </div>

          <h5 class="mt-3">Fullscreen</h5>
          <button class="btn btn-primary" @click="fullscreenLoad">
            Load fullscreen
          </button>

          <h5 class="mt-3">Parent Container</h5>
          <button class="btn btn-primary" @click="containerLoad">
            Load this container
          </button>

          <h5 class="mt-3">Dialog with description</h5>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#loading"
          >
            Load dialog window
          </button>
          <div
            class="modal"
            id="loading"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-loading"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-body text-center">
                  <div class="is-loading"></div>
                  Message to display while loading
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
loading dialog:
&lt;div class="modal" id="loading" tabindex="-1" role="dialog" aria-hidden="true"&gt;
  &lt;div class="modal-dialog modal-dialog-centered modal-dialog-loading" role="document"&gt;
    &lt;div class="modal-content"&gt;
      &lt;div class="modal-body text-center"&gt;
        &lt;div class="is-loading"&gt;&lt;/div&gt;
        Message to display while loading
      &lt;/div&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide">
        <ul>
          <li>
            Add class .is-loading to any container element and the spinner will
            position itself in the center and apply an opaque layer on top of
            the content.
          </li>
          <li>Buttons use the same class but the spinner is not centered</li>
          <li>
            For adding a fullscreen loader apply class .is-loading--fullscreen
            to the body element. It will work on any other element as well but
            there may be potential conflicts with other fixed items
          </li>
        </ul>
      </div>
    </div>

    <h3 id="progress-bar">Progress Bar</h3>
    <div class="sg-component">
      <div class="sg-component__content">
        <div class="sg-component__view">
          <div class="progress">
            <div
              class="progress-bar bg-info"
              role="progressbar"
              style="width: 66%;"
              aria-valuenow="66"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Step 2 of 3
            </div>
          </div>
        </div>
        <div class="sg-component__code">
          <div
            class="sg-component__toggle js-toggle-code"
            data-text-swap="Hide Code"
            v-toggleCode
          >
            Show Code
          </div>
          <pre v-highlight><code class="language-html">
&lt;div class="progress"&gt;
  &lt;div class="progress-bar bg-success" role="progressbar" style="width: 66%;" aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"&gt;Step 2 of 3&lt;/div&gt;
&lt;/div&gt;
                </code></pre>
        </div>
      </div>
      <div class="sg-component__guide"></div>
    </div>
  </component-frame>
</template>

<script>
export default {
  name: "utilities",
  data() {
    return {
      title: "Utilities",
      menu: false,
      active: false,
      fieldActive: false,
      fullActive: false,
      containerActive: false
    };
  },
  methods: {
    clickLoad: function() {
      this.active = !this.active;
    },
    inputLoad: function() {
      this.fieldActive = !this.fieldActive;
    },
    fullscreenLoad: function() {
      this.fullActive = !this.fullActive;
    },
    containerLoad: function() {
      this.containerActive = !this.containerActive;
    }
  }
};
</script>
